export var SearchList = [
  { labe: '地址名称', code: 'addressName', type: 'input' },
  { labe: '地质属性', code: 'addressProperty', type: 'input' }
]
export var SearchData = {
  addressName: '',
  addressProperty: ''
}
export var tableField = [
  { label: '编码', code: 'werks', width: '50px', type: 'input' },
  { label: '工厂名称', code: 'name1', type: 'input', width: '' },
  { label: '地址', type: 'input', width: '', code: 'adrnr' },
  { label: '备注', code: 'minBatch', type: 'input', width: '' }
]
